/* eslint-disable no-unused-vars */
import React from 'react';
import TopHeader from './TopHeader';

export default function MainHeader(props) {

  return (
    <header>
      <TopHeader setLanguage={props.setLanguage} firstName={props.firstName} />
      {/* <Grid item container xs={12} className={styledClasses.main_header}> */}
        {/* <Grid item container xs={5}>
          <img src={OrientalImage} className={styledClasses.oriental} tabIndex='0' alt='Oriental Logo' aria-label="Oriental Logo" />
        </Grid> */}
      {/* </Grid> */}
    </header>
  );
}
