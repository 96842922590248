import React, { useEffect } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useTranslation } from '../contexts/Localization';
import { useGlobalUserData } from '../lib/globalUserData';
import { FISWrapper } from './FISWrapper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tick from '../images/auto-img/Tick.png';

const AccountUnlocked = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();

  return (
    <FISWrapper>
      <Box>
        <img src={Tick} />
      </Box>
      <Box className={styles.accountUnlocked}>
        Account Unlocked
      </Box>
      <Box className={styles.accountUnlockedMessage}>
        Your account has been reset now. Please try login using your user id and password
      </Box>
      <Box className={styles.btn_wrapper} style={{width: '100%'}}>
        <Button
          variant="text"
          onClick={() => changeStep('fislogin')}
          className={styles.login_button}
          type="button"
        >
          {t('Sign In')}
        </Button>
      </Box>
    </FISWrapper>
  );
};

export default AccountUnlocked;
