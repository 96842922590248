import { createTheme } from '@material-ui/core/styles';

const primaryColor = '#661C69';
const secondaryColor = '#661C69';
const textColor = '#000000';
const white = '#ffffff';

const THEME = createTheme({
  palette: {
    primary: {
      light: '#',
      main: primaryColor, //ff3f6c //335eea
      dark: '#',
    },
    secondary: {
      light: '#5a74c7',
      main: secondaryColor, //ff3f6c //335eea
      dark: '#',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: '45px',
        lineHeight: '45px',
        fontSize: '14px',
        textTransform: 'capitalize',
        borderRadius: '50px',
        fontWeight: '500',
      },
      contained: {
        color: 'white',
        border: `1px solid ${secondaryColor}`,
        textAlign: 'center',
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0.11px',
        opacity: '1',
        '&:hover': {
          backgroundColor: secondaryColor,
        },
      },

      outlined: {
        border: `1px solid ${secondaryColor}`,
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0.11px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 300,
        letterSpacing: '0.1px',
        color: textColor,
        marginTop: '15px',
        marginBottom: '4px',
      },
    },
    // MuiOutlinedInput: {
    //   root: {
    //     marginBottom: '0px',
    //     color: textColor,
    //     '& input': {
    //       height: '41px',
    //       lineHeight: '42px',
    //       padding: '0 12px',
    //       fontSize: '14px',
    //       color: textColor,
    //       fontWeight: 400,
    //       '&::placeholder': {
    //         color: 'textColor',
    //         opacity: 1,
    //       },
    //     },
    //     '&$focused $notchedOutline': {
    //       borderColor: '#555858',
    //       borderWidth: 1,
    //     },
    //   },
    //   notchedOutline: {
    //     borderWidth: 1,
    //     borderColor: '#555858',
    //   },
    // },
    MuiSelect: {
      root: {
        marginBottom: '0px',
        height: '41px',
        padding: '0 12px',
        fontSize: '14px',
        lineHeight: '41px',
        '& input': {
          color: textColor,
          fontWeight: 300,
        },
        '& $notchedOutline': {
          borderColor: '#555858',
          borderWidth: 1,
        },
      },
      select: {
        '&:focus': {
          backgroundColor: '#ffffff',
        },
      },
      nativeInput: {
        color: textColor,
      },
      underline: {
        borderBottom: '2px solid white',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F4F4F4',
        opacity: 1,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
      toolbarBtnSelected: {
        color: '#000000',
        fontFamily: 'Arial',
        opacity: 1,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#661C69',
        opacity: 1,
      },
      dayLabel: {
        opacity: 1,
        fontFamily: 'Arial',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: '#661C69',
        opacity: 1,
      },
    },
    MuiPickersDay: {
      day: {
        color: '#661C69',
        opacity: 1,
      },
      daySelected: {
        backgroundColor: '#661C69',
        color: white,
        opacity: 1,
        '&:hover': {
          backgroundColor: '#661C69',
        },
      },
      current: {
        color: '#661C69',
        opacity: 1,
      },
    },
  },
  typography: {
    useNextVariants: true,
    // fontFamily: ["-apple-system", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    //     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'].join(","),
    h4: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: textColor,
      letterSpacing: '0.11px',
    },
  },
});

export default THEME;
