import { orderBy } from './orderBy';
import { uniqueElementsBy } from './uniqueElementsBy';

export const formatDevices = (deviceList) => {
  const uniqDevices = uniqueElementsBy(
    deviceList.map((d) => ({
      label: d.label,
      device: d.groupLabel === 'EMAIL' ? 'EMAIL' : 'PHONE',
      deviceInfo: []
    })),
    (a, b) => a.label == b.label
  );
  for (const uniqDevice of uniqDevices) {
    for (const device of deviceList) {
      if (uniqDevice.label === device.label) {
        const { label, ...deviceData } = device;
        uniqDevice.deviceInfo.push(deviceData);
      }
    }
  }

  return orderBy(
    uniqDevices.map((ud) => ({
      ...ud,
      deviceInfo: orderBy(ud.deviceInfo, ['groupLabel'], ['asc']),
    })),
    ['device'],
    ['desc']
  );
};
