import React, { useEffect } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useTranslation } from '../contexts/Localization';
import { useGlobalUserData } from '../lib/globalUserData';
import { FISWrapper } from './FISWrapper';
import AccountLockedError from '../images/auto-img/AccountLockedError.png';

const AccountLocked = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();

  return (
    <FISWrapper>
      <Box>
        <img src={AccountLockedError} />
      </Box>
      <Box className={styles.accountUnlocked}>
        Account Locked
      </Box>
      <Box className={styles.accountUnlockedMessage}>
        Your account has been locked due to multiple incorrect attempts. Click Trouble Signing In to reset your access information.
      </Box>
      <Box className={styles.btn_wrapper} style={{ width: '100%' }}>
        <Button
          variant="text"
          onClick={() => changeStep('fistroublesignin')}
          className={styles.login_button}
          type="button"
        >
          {t('Trouble Signing In')}
        </Button>
      </Box>
    </FISWrapper>
  );
};

export default AccountLocked;
