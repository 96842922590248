import React from 'react';
import { LoaderStyles } from './LoaderStyles';
import {
  Backdrop,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import './LoaderCSS.css';

const useStyles = LoaderStyles();

const LoaderFis = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      role="presenter"
      open={open}
      aria-hidden="true"
      aria-disabled="true"
    >
      <Grid container className={classes.center_style}>
        <Grid
          item
          xs={12}
          className={classes.center_style}
          style={{ marginBottom: 40 }}
        >
          {/* <img
            src={OverlayLoader}
            className={classes.loaderStyle}
            alt="Loading..."
          /> */}
          {/* <div className="donut" /> */}
          <CircularProgress
            style={{ color: '#000000'}}
            size={35}
            thickness={6}
            data-testid="loading"
          />
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default LoaderFis;
