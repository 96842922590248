export const orderBy = (arr, props, orders) =>
  [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        const [p1, p2] =
          orders && orders[i] === 'desc'
            ? [b[prop], a[prop]]
            : [a[prop], b[prop]];
        if (p1 > p2) {
          acc = 1;
        } else {
          if (p1 < p2) {
            acc = -1;
          } else {
            acc = 0;
          }
        }
      }
      return acc;
    }, 0)
  );
