import React from 'react';
import { Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useTranslation } from '../contexts/Localization';
import { useGlobalUserData } from '../lib/globalUserData';
import { FISWrapper } from './FISWrapper';
import Tick from '../images/auto-img/Tick.png';

const ChangePasswordSuccess = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();
  return (
    <FISWrapper>
      <Box>
        <img src={Tick} />
      </Box>
      <Box className={styles.accountUnlocked}>
        Password Updated!
      </Box>
      <Box className={styles.accountUnlockedMessage}>
        Your password has been changed successfully. Use your new password to sign in.
      </Box>
      <Box className={styles.btn_wrapper} style={{ width: '100%' }}>
        <Button
          variant="text"
          onClick={() => changeStep('fislogin')}
          className={styles.login_button}
          type="button"
        >
          {t('Sign In')}
        </Button>
      </Box>
    </FISWrapper>
  );
};

export default ChangePasswordSuccess;
