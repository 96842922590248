/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useGlobalUserData } from '../lib/globalUserData';
import axios from 'axios';
import LoaderFis from '../components/Loader/LoaderFIS';
import { useTranslation } from '../contexts/Localization';
import { fetchFIS } from '../utils/fetchFIS';
import parseJWT from '../utils/parseJWT';
import { getMessage } from '../utils/status';

const Terms = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert } =
    useGlobalUserData();
  const { policy } = userData;
  const { apiLanguage, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onCheckBoxChange = () => {
    setIsChecked(!isChecked);
  }

  const onButtonClick = async ({ cancel }) => {
    const payLoad = {
      step: "policy",
      data: {
        date: policy.date,
        read: cancel ? false : isChecked,
        policyText: policy.policyText,
        signature: policy.signature,
        pageTitle: policy.pageTitle,
        userLocation: null,
        skip: false,
        cancel,
        cookie: userData.cookie
      }
    };
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/policy/agreement`,
        payLoad,
        {
          withCredentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code !== 200) {
          setLoading(false);
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        } else {
          if (res.data.result[0].nextStep &&
            res.data.result[0].nextStep.id === 'policy') {
            setLoading(false);
            setIsChecked(false);
            updateUserData({
              ...userData,
              policy: {
                pageTitle: res.data.result[0].nextStep.params.pageTitle,
                policyText: res.data.result[0].nextStep.params.policyText,
                signature: res.data.result[0].nextStep.params.signature,
                date: res.data.result[0].nextStep.params.date
              }
            });
          }
          else if (res.data.result[0].nextStep &&
            res.data.result[0].nextStep.id === 'username') {
            setLoading(false);
            changeStep('fislogin');
          }
          else {
            axios
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                {
                  cookie: userData.cookie,
                  replaceToken: true,
                },
                {
                  withCredentials: 'include',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                    appid: 'idmauto',
                    language: apiLanguage,
                  },
                }
              )
              .then(async (resp) => {
                const { id_token } = resp.data.result[0];
                const accessToken = id_token.substring(0, id_token.lastIndexOf('.'));
                axios
                  .post(
                    `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                    {
                      cookie: res.data.result[0].cookies[0],
                      accessTokenKey: parseJWT(accessToken)?.accessToken,
                    },
                    {
                      withCredentials: 'include',
                      // crossDomain: true,
                      headers: {
                        appid: 'idmauto',
                        language: apiLanguage,
                        Accept: 'application/json',
                        'x-api-key':
                          process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(async (respn) => {
                    localStorage.setItem('fisToken', respn.data.accessToken);
                    async function handShakeFIS() {
                      const handshakeData = await fetchFIS(
                        respn.data.accessToken
                      );
                      console.dir(handshakeData);
                      return handshakeData;
                    }
                    if (userData.fisGetRequest) {
                      const handshakeResponse = await handShakeFIS();
                      if (!handshakeResponse) {
                        showGlobalAlert({
                          type: 'error',
                          message: getMessage('defaultCode').body,
                        });
                        setLoading(false);
                        changeStep('fislogin');
                      } else {
                        window.open(handshakeResponse.redirectUrl, '_self');
                      }
                    } else {
                      setLoading(false);
                      console.log(respn.data.accessToken);
                      window.open(
                        `${process.env.REACT_APP_AUTO_LOGIN_UI_URL}?userType=dealer&accessToken=${respn.data.accessToken}&idToken=${id_token}`,
                        '_self'
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    updateUserData({
                      ...userData,
                      password: '',
                      userpassword: '',
                    });
                    showGlobalAlert({
                      type: 'error',
                      message: getMessage('defaultCode').body,
                    });
                    changeStep('fislogin');
                  });
              })
              .catch((err) => {
                setLoading(false);
                updateUserData({
                  ...userData,
                  userpassword: '',
                  password: '',
                });
                console.error(err);
              });
          }
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={4}>
          <Box
            className={styles.container}
            style={{ alignItems: 'flex-start' }}
          >
            <Box>
              {t('Welcome to Digital one Consumer Banking application.')}
              {t(
                'Please go over the terms of use and record your consent to continue using the application.'
              )}
            </Box>
            <Box className={styles.boxMarginTop}>
              {policy && policy.policyText}
            </Box>
            <Box style={{ marginTop: '10px' }}>
              <input
                type="checkbox"
                data-testid="termsCheckBox"
                id="policy"
                name="policy"
                checked={isChecked}
                onChange={onCheckBoxChange}
              />
              <label for="policy">
                {t(
                  'I have read, understood and accepted to the terms of use/features and services provided by Digital One Banking application.'
                )}
              </label>
            </Box>
            <Box className={styles.btn_wrapper}>
              <Button
                variant="text"
                className={styles.continue_button}
                onClick={() => onButtonClick({ cancel: false })}
                disabled={!isChecked}
              >
                {t('Continue')}
              </Button>
              <Button
                variant="text"
                className={styles.button_cancel}
                onClick={() => onButtonClick({ cancel: true })}
              >
                {t('Cancel')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <LoaderFis open={loading} />
    </>
  );
};

export default Terms;
