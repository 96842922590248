import { LanguageProvider } from './contexts/Localization';
import { UserDataProvider } from './lib/globalUserData';
import Wizard from './Wizard/Wizard';
const App = () => (
  <LanguageProvider>
    <UserDataProvider>
      <Wizard />
    </UserDataProvider>
  </LanguageProvider>
);

export default App;
