import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@material-ui/core';

import { useGlobalUserData } from '../lib/globalUserData';
import MainHeader from '../components/Header/MainHeader';
import Footer from '../components/Footer/Footer';
import MigrationChangePassword from '../MigrationWeb/MigrationChangePassword';
import ChangePasswordSuccess from '../Enrollment/ChangePasswordSuccess';
import Login from '../Enrollment/Login';
import FISTroubleSIgnIn from '../Enrollment/TroubleSignIn';
import Terms from '../Enrollment/Terms';
import OtpChoice from '../Enrollment/OtpChoice';
import Otp from '../Enrollment/Otp';
import OtpChoiceLogin from '../Enrollment/OtpChoiceLogin';
import OtpLogin from '../Enrollment/OtpLogin';
import { getMessage } from '../utils/status';
import { getURLParameters } from '../utils/getUrlParameters';
import LoaderFis from '../components/Loader/LoaderFIS';
import { formatDevices } from '../utils/formatDevices';
import { useTranslation } from '../contexts/Localization';
import AccountUnlocked from '../Enrollment/AcountUnlocked';
import AccountLocked from '../Enrollment/AccountLocked';

const Wizard = () => {
  const {
    step,
    changeStep,
    updateGlobalToken,
    showGlobalAlert,
    updateUserData,
    userData,
  } = useGlobalUserData();

  const [loading, setLoading] = useState(false);

  const urlParam = new URLSearchParams(window.location.search);

  const getCurrentComponent = (stepName) => {
    const components = {
      // troublesignin: <TroubleSignin />,
      migrationchangepassword: <MigrationChangePassword />,
      migrationchangepasswordsuccess: <ChangePasswordSuccess />,
      fisloginpassword: <Login />,
      migrationui: <Login />,
      fislogin: <Login />,
      fisloginusername: <Login />,
      fistroublesignin: <FISTroubleSIgnIn />,
      fisterms: <Terms />,
      fistroubleotpchoice: <OtpChoice />,        
      fisotp: <Otp />,
      fisloginotpchoice: <OtpChoiceLogin />,
      fisloginotp: <OtpLogin />,
      accountUnlocked: <AccountUnlocked />,
      accountLocked: <AccountLocked />
    };
    return components[stepName];
  };

  useEffect(() => {
    const paramsObj = getURLParameters(urlParam.toString());
    if (paramsObj.unlockStatus) {
      console.log('paramsObj.unlockStatus')
      console.log(paramsObj.unlockStatus)
      const unlockCode = parseInt(paramsObj.unlockStatus, 10);
      if (unlockCode === 200) {
        showGlobalAlert({
          type: 'success',
          message:
            'Your account has been reset, please try to login now.',
        });
      } else {
        const { body } = getMessage(unlockCode);
        showGlobalAlert({
          type: 'error',
          message: body,
        });
      }
    }
  }, [])
  
  return (
    <>
      <CssBaseline />
      <MainHeader />
      {/* <Container component="main" maxWidth="xs"> */}
      <div style={{maxWidth: '100%', overflowX: 'hidden'}}>
        {getCurrentComponent(step)}
      </div>
      {/* </Container> */}
      <Footer />
      <LoaderFis open={loading} />
    </>
  );
};

export default Wizard;
