import React from 'react';
import Axios from 'axios';

export const fetchFIS = async (token) => {
  try {
    const fetchParams = new URLSearchParams();
    fetchParams.append('token', token);
    const res = await Axios.post(
      `${process.env.REACT_APP_FIS_LOGIN_UI_URL}services/authenticate`,
      fetchParams,
      {
        withCredentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log('Error after the FIS call');
    console.error(err);
  }
};
