import React from 'react';
import { Grid } from '@material-ui/core';
import Subtract from '../../images/auto-img/Subtract.svg';
import FooterLogo from '../../images/auto-img/FooterLogo.svg';
import styles from './Footer.module.scss';

const Footer = () => {

  return (
    <footer style={{ backgroundColor: '#fff' }}>
      <Grid className={styles.logo}>
        <div>
          <img src={Subtract} />
        </div>
        <div>
          <img data-testid='footerimg' src={FooterLogo} />
        </div>
        <div style={{ width: '0px' }}></div>
      </Grid>
    </footer>
  );
};

export default Footer;
