import React, { useEffect, useState } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import './Requirement.module.scss';
import { useTranslation } from "../../contexts/Localization";

export const Requirement = ({ value, requirement }) => {
  const [isValid, setIsValid] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setIsValid(requirement.validator(value));
  }, [value, requirement]);

  return (
    <div className="requirement">
      <div
        className={isValid ? 'valid' : 'invalid'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 5,
          // border: '2px solid red'
        }}
      >
        <div>
          {isValid ? (
            <CheckCircleIcon
              style={{ width: '0.7em', height: '0.7em', color: '#f5821f' }}
            />
          ) : (
            <RadioButtonUncheckedIcon
              style={{ width: '0.7em', height: '0.7em', color: '#f5821f' }}
            />
          )}
        </div>
        <div style={{ fontSize: '14px' }}>{t(requirement.text)}</div>
      </div>
    </div>
  );
};