/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  OutlinedInput,
  Grid,
  Box,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import LoaderFis from '../components/Loader/LoaderFIS';
import { useGlobalUserData } from '../lib/globalUserData';
import hideIcon from '../images/eyeIconHide.png';
import showIcon from '../images/eyeIcon.png';
import { useTranslation } from '../contexts/Localization';
import styles from './MigrationWeb.module.scss';
import FisAlert from '../Enrollment/FisAlert';
import { getMessage } from '../utils/status';
import { enrollTooltipStyles } from './EnrollTooltipStyles';
import { Requirements } from '../components/Requirements/Requirements';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import parseJwt from '../utils/parseJWT';
import { fetchFIS } from '../utils/fetchFIS';
import { FISWrapper } from '../Enrollment/FISWrapper';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const useStyles = enrollTooltipStyles();

const MigrationChangePassword = () => {
  const classes = useStyles();
  const { changeStep, userData, showGlobalAlert, globalAlert, updateUserData } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    reenterPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    newPassword: '',
    reenterPassword: '',
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showHideIcon, setShowHideIcon] = useState({
    oldPassword: hideIcon,
    newPassword: hideIcon,
    reenterPassword: hideIcon,
  });
  const [loading, setLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [reenterPasswordValid, setReenterPasswordValid] = useState(false);

  const [passwordToggle, setPasswordToggle] = useState({
    oldPassword: true,
    newPassword: true,
    reenterPassword: true,
  });

  const reqArray = [
    {
      validator: (val) => val.length > 7 && val.length < 16,
      text: 'Password must be a minimum of 8 characters and a maximum of 15 characters',
      reqSatisfied: false,
    },
    {
      validator: (val) =>
        (/[A-Z]{1}/.test(val) || /[a-zÑñÁáÉéÍíÓóÚúü]{1}/.test(val)) &&
        /(\D*\d)/.test(val) &&
        !/[><\\']/.test(val) &&
        /^(?!.*(.)\1\1.*).*$/i.test(val),
      text: "Password must at least contain one letter and one number. Cannot contain the special characters of \\, <, >, '. Cannot repeat more than 2 the same characters in a row.",
      reqSatisfied: false,
    },
    {
      validator: (val) => !/\s/.test(val),
      text: 'Cannot include spaces',
      reqSatisfied: false,
    },
    {
      validator: (val) => val !== userData.userid,
      text: 'User ID and Password cannot be the same',
      reqSatisfied: false,
    },
  ];

  const reenterArray = [
    {
      validator: (val) => val === formData.newPassword,
      text: 'Passwords must be the same',
      reqSatisfied: false,
    },
  ];

  useEffect(() => {
    setFormData({
      ...formData,
      ...userData,
    });
    // showGlobalAlert({
    //   type: 'success',
    //   message: 'Please change your password to continue',
    // });
    return () => {
      showGlobalAlert({
        type: '',
        message: '',
      });
    };
  }, []);

  const submit = () => {
    setLoading(true);
    setPasswordToggle({
      oldPassword: true,
      newPassword: true,
      reenterPassword: true,
    });
    setShowHideIcon({
      oldPassword: hideIcon,
      newPassword: hideIcon,
      reenterPassword: hideIcon,
    });
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/changepassword`,
        {
          step: 'changepassword',
          cookieData: null,
          data: {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
            confirmPassword: formData.reenterPassword,
            cookie: userData.cookie,
          },
        },
        {
          withCredentials: 'include',
          headers: {
            appid: 'idmauto',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        if (res.data.status.code === 200) {
          setLoading(false);
          changeStep('migrationchangepasswordsuccess');
        } else {
          setLoading(false);
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        }
      })
      .catch((err) => {
        setFormData({
          ...formData,
          oldPassword: '',
          newPassword: '',
          reenterPassword: '',
        });
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        console.error(err);
        setLoading(false);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  const passwordIconHandler = (passname, e) => {
    e.preventDefault();
    setPasswordToggle({
      ...passwordToggle,
      [passname]: !passwordToggle[passname],
    });
    // const icon = showHideIcon[passname] === hideIcon ? showIcon : hideIcon;
    // setShowHideIcon({
    //   ...showHideIcon,
    //   [passname]: icon,
    // });
  };

  useEffect(() => {
    setSubmitDisabled(
      !formData.oldPassword ||
      !formData.newPassword ||
      !formData.reenterPassword ||
      // Object.values(formErrors).some((value) => value !== '') ||
      !reenterPasswordValid ||
      !passwordValid
    );
  }, [formData, passwordValid, reenterPasswordValid]);

  useEffect(() => {
    if (
      formData.newPassword !== formData.reenterPassword &&
      formData.newPassword.length === formData.reenterPassword.length
    ) {
      setFormErrors({
        ...formErrors,
        reenterPassword: 'Passwords must be the same',
      });
    } else if (
      formData.newPassword.length === formData.reenterPassword.length
    ) {
      setFormErrors({ ...formErrors, reenterPassword: '' });
    }
  }, [formData.newPassword, formData.reenterPassword]);

  const onFormData = (e) => {
    if (e.target.name === 'newPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setPasswordValid(
        !reqArray
          .map((req) => req.validator(e.target.value))
          .some((val) => val === false)
      );
      setReenterPasswordValid(e.target.value === formData.reenterPassword);
    } else if (e.target.name === 'reenterPassword') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setReenterPasswordValid(e.target.value === formData.newPassword);
    } else {
      reenterArray[0].validator();
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <FISWrapper>
      <Box style={{ width: '100%' }}>
        <h4 className={styles.middle_heading}>{t('Reset your password')} </h4>
        {globalAlert.message && (
          <FisAlert message={globalAlert.message} type={globalAlert.type} />
        )}
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputLabel
            htmlFor="oldPassword"
            aria-label="Old Password Required"
          >
            {t('Old Password')}
          </InputLabel>
          <OutlinedInput
            id="oldPassword"
            name="oldPassword"
            // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
            onChange={onFormData}
            placeholder={t('Enter your old password')}
            fullWidth
            inputProps={{ maxLength: 15 }}
            autoComplete="off"
            type={passwordToggle.oldPassword ? 'password' : 'text'}
            value={formData.oldPassword}
            endAdornment={
              <InputAdornment
                position="end"
                style={{
                  marginLeft: '0',
                  marginRight: '-7px',
                  maxWidth: '35px',
                }}
                tabIndex="0"
              >
                <IconButton
                  onClick={(e) => passwordIconHandler('oldPassword', e)}
                  tabIndex="0"
                  data-testid="oldPasswordIcon"
                  fill='#909090'
                >
                  {passwordToggle.oldPassword === false ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <InputLabel
            htmlFor="newPassword"
            aria-label="New Password Required"
          >
            {t('New Password')}
          </InputLabel>
          <Tooltip
            disableHoverListener
            placement={'bottom'}
            leaveTouchDelay={250}
            enterTouchDelay={50}
            leaveDelay={250}
            TransitionComponent={Zoom}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={
              <>
                <Requirements
                  value={formData.newPassword || ''}
                  requirements={reqArray}
                  onValidChange={(isValid) => { }}
                />
                {
                  <>
                    <div>
                      <InfoOutlinedIcon
                        style={{
                          width: '0.7em',
                          height: '0.7em',
                          color: '#f5821f',
                        }}
                      />
                      <span style={{ fontSize: '14px' }}>
                        {t('**Note: Password is Case sensitive')}
                      </span>
                    </div>
                    <div>
                      <InfoOutlinedIcon
                        style={{
                          width: '0.7em',
                          height: '0.7em',
                          color: '#f5821f',
                        }}
                      />
                      <span style={{ fontSize: '14px' }}>
                        {t('**Note: Password should not match last 10 passwords')}
                      </span>
                    </div>
                  </>
                }
              </>
            }
          >
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
              onChange={onFormData}
              placeholder={t('Enter your new password')}
              fullWidth="newPassword"
              data-testid="newPassword"
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.newPassword ? 'password' : 'text'}
              value={formData.newPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: '0',
                    marginRight: '-7px',
                    maxWidth: '35px',
                  }}
                  tabIndex="0"
                >
                  <IconButton
                    onClick={(e) => passwordIconHandler('newPassword', e)}
                    tabIndex="0"
                    data-testid="hidePasswordIcon"
                  >
                    {passwordToggle.newPassword === false ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
          <span
            style={{
              font: 'normal normal normal 14px/16px Arial',
              letterSpacing: '0.1px',
              color: '#DF0000',
              opacity: 1,
            }}
            role="alert"
          >
            {t(formErrors.newPassword)}
          </span>
          <InputLabel
            htmlFor="reenterPassword"
            aria-label="Confirm Password Required"
          >
            {t('Confirm Password')}
          </InputLabel>
          <Tooltip
            disableHoverListener
            placement={'bottom'}
            leaveTouchDelay={250}
            enterTouchDelay={50}
            leaveDelay={250}
            TransitionComponent={Zoom}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={
              <>
                <Requirements
                  value={formData.reenterPassword || ''}
                  requirements={reenterArray}
                  onValidChange={(isValid) => { }}
                />
                {
                  <>
                    <InfoOutlinedIcon
                      style={{
                        width: '0.7em',
                        height: '0.7em',
                        color: '#f5821f',
                      }}
                    />
                    <span style={{ fontSize: '14px' }}>
                      {t('**Note: Password is Case sensitive')}
                    </span>
                  </>
                }
              </>
            }
          >
            <OutlinedInput
              id="reenterPassword"
              name="reenterPassword"
              // className={`${classes.outlined_inputs} ${classes.input_disabled_style}`}
              onChange={onFormData}
              placeholder={t('Re-Enter your new password')}
              fullWidth
              inputProps={{ maxLength: 15 }}
              autoComplete="off"
              type={passwordToggle.reenterPassword ? 'password' : 'text'}
              value={formData.reenterPassword}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    marginLeft: '0',
                    marginRight: '-7px',
                    maxWidth: '35px',
                  }}
                  tabIndex="0"
                >
                  <IconButton
                    onClick={(e) => passwordIconHandler('reenterPassword', e)}
                    tabIndex="0"
                    data-testid="reenterPasswordIcon"
                  >
                    {passwordToggle.reenterPassword === false ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
          <span
            style={{
              font: 'normal normal normal 14px/16px Arial',
              letterSpacing: '0.1px',
              color: '#DF0000',
              opacity: 1,
            }}
            role="alert"
          >
            {t(formErrors.reenterPassword)}
          </span>
          <Grid container spacing={2} className={styles.btn_wrapper_pass}>
            {/* <Grid item sm={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => changeStep('fistroubleotpchoice')}
              >
                {t('Back')}
              </Button>
            </Grid> */}
            {/* <Grid item sm={6}> */}
            <Button
              className={styles.button_cancel}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => changeStep('fislogin')}
            // disabled={submitDisabled}
            >
              {t('Cancel')}
            </Button>
            <Button
              className={styles.login_button}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={submit}
              disabled={submitDisabled}
            >
              {t('Change Password')}
            </Button>
            {/* </Grid> */}
          </Grid>
        </form>

        <LoaderFis open={loading} />

      </Box>
    </FISWrapper>
  );
};

export default MigrationChangePassword;
