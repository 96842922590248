/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Select, MenuItem, Box, Link, OutlinedInput } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import LoaderFis from '../components/Loader/LoaderFIS';
import { useGlobalUserData } from '../lib/globalUserData';
import { useTranslation } from '../contexts/Localization';
import styles from './Enrollment.module.scss';
import selectStyles from './CustomSelect.module.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FISWrapper } from './FISWrapper';
import phoneIcon from '../images/phoneIcon.svg';
import { getMessage } from '../utils/status';
import FisAlert from './FisAlert';

const OtpChoiceLogin = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert, globalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({ otpchoice: 'default' });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({
    label: '',
    deviceInfo: [],
  });

  useEffect(() => {
    showGlobalAlert({ type: '', message: '' });
    setFormData({
      ...formData,
      ...userData,
      otpchoice: userData.otpDeviceList[0].label,
    });
    const selectDev = userData.otpDeviceList[0];
    setSelectedDevice(selectDev);
  }, []);

  const getSendOtpText = (label) => {
    switch (label) {
      case 'SMS':
      case 'MOBILE':
        return (<>{t('Send SMS')}</>);
      case 'VOICE':
      case 'IVR':
        return (<>{t('Send Voice Call')}</>);
      case 'EMAIL':
      default:
        return (<>{t('Send Code to Email')}</>);
    }
  };

  const submitOtpChoice = (otpchoice) => {
    setLoading(true);
    updateUserData({ ...userData, otpchoice });
    // changeStep('fisloginotp');
    const payLoad = {
      data: {
        loginName: userData.userid,
        browserFootprint:
          '557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e',
        selectedDeviceId: otpchoice,
        next: true,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/otpchoice`,
        payLoad,
        {
          withCredentials: 'include',
          headers: {
            appid: 'idmauto',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        updateUserData({ ...userData, otpchoice });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        } else {
          changeStep('fisloginotp');
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  const onFormData = (e) => {
    const selectDev = userData.otpDeviceList.find(
      (destn) => destn.label === e.target.value
    );
    setSelectedDevice(selectDev);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      captchaerror: '',
      [e.target.name]: '',
    });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: '', message: '' });
    changeStep('fislogin');    
  }

  return (
    <>
      <FISWrapper>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            gap: '15px'
          }}
        >
          <div style={{marginTop: '8px'}}>
            <ArrowBackIcon
              onClick={navigateBack}
              data-testid="backButtonOTPChoiceLogin"
            />
          </div>
          <Box className={styles.heading_verification}>
            {t('Additional Verification Required')}
          </Box>
        </Box>
        <Box>
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
        </Box>
        <Box className={styles.subHeading}>
          {t(
            'We see this is the first time you logged in on this device. Please verify your device to continue.'
          )}
        </Box>
        <form style={{ width: '100%', margin: '20px 0', height: '65px' }}>
          <Box className={styles.boxMarginTop} style={{ height: '40px' }}>
            <Box className={styles.otpchoiceinputlabel}>Deliver my code to:</Box>
            <Select
              className={styles.customInput}
              id="otpchoice"
              name="otpchoice"
              variant="outlined"
              role="listbox"
              value={formData.otpchoice || 'default'}
              onChange={onFormData}
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              classes={{
                icon: 'select-icon',
                select:
                  formData.otpchoice === ''
                    ? selectStyles.select_inputs_placeholder
                    : selectStyles.select_style,
              }}
              input={<OutlinedInput />}
            >
              <MenuItem role="option" value="default">
                {t('Select a destination for your One Time Passcode')}
              </MenuItem>
              {userData.otpDeviceList.map(({ label }) => (
                <MenuItem
                  className={selectStyles.otp_menuitem_style}
                  key={label}
                  role="option"
                  value={label}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </form>
        <Box
          className={styles.trouble_otpchoice_btn_wrapper}
        >
          {selectedDevice?.deviceInfo.length > 0 &&
            selectedDevice?.deviceInfo.map((sendMode) => (
              <>
                <Button
                  type="button"
                  key={sendMode.value}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => submitOtpChoice(sendMode.value)}
                  className={styles.login_button}
                >
                  {getSendOtpText(sendMode.groupLabel)}
                </Button>
              </>
            ))}
        </Box>
        <Box className={styles.link_wrapper} id="linkBoxWrapper">
          <Link
            component="button"
            id="fislogin"
            color="primary"
            variant="body2"
            onClick={() => {
              changeStep('fislogin');
            }}
            style={{ textDecoration: 'underline' }}
          >
            {t('Log in with a different User ID')}
          </Link>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
    </>
  );
};

export default OtpChoiceLogin;
