import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  OutlinedInput,
  Button,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useGlobalUserData } from '../lib/globalUserData';
import LoaderFis from '../components/Loader/LoaderFIS';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from '../contexts/Localization';
import { getMessage } from '../utils/status';
import FisAlert from './FisAlert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { formatDevices } from '../utils/formatDevices';
import { isValidEmail } from '../utils/isValidEmail';
import { emailMask } from '../utils/mask';
import { FISWrapper } from './FISWrapper';

const FISTroubleSIgnIn = () => {
  const { changeStep, userData, updateUserData, showGlobalAlert, globalAlert } = useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [formData, setFormData] = useState({
    troublesigninforgotuser: '',
    troublesigninforgotpass: '',
    troublesigninaccountlocked: '',
    accountType: 'empty',
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    troublesigninforgotuser: '',
    troublesigninforgotpass: '',
    troublesigninaccountlocked: '',
  });
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [rememberOptions, setRememberOptions] = useState([
    {
      name: 'troublesigninforgotuser',
      id: 'forgotusername',
      value: 'I forgot my User ID',
      isChecked: true,
    },
    {
      name: 'troublesigninforgotpass',
      id: 'forgotpassword',
      value: 'I forgot my Password',
      isChecked: false,
    },
    {
      name: 'troublesigninaccountlocked',
      id: 'accountlocked',
      value: 'My account is locked',
      isChecked: false,
    }
  ]);

  useEffect(() => {
    updateUserData({
      ...userData,
      troubleSigninStep: 'troublesigninforgotuser',
      flow: 'TROUBLE_SIGN_IN',
    });
    showGlobalAlert({ type: '', message: '' });
    return () => {
      showGlobalAlert({ type: '', message: '' });
    };
  }, []);

  const usernameFullFlow = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/forgotusername`,
        {
          step: 'username',
          context: 'trouble sign in recover user id',
          data: {
            next: 'true',
            emailOrLoginID: formData[userData.troubleSigninStep],
            email: 'true',
            issueType: userData.issueTypes[userData.troubleSigninStep],
          },
        },
        {
          // withCredentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 501 || res.data.status.code === 503) {
          showGlobalAlert({ type: 'error', message: 'We could not locate an account with the information provided. Please try again or contact customer support.' });
        } else if (res.data.status.code === 102) {
          showGlobalAlert({ type: 'error', message: getMessage(res.data.status.code).body });
        } else if (res.data.status.code !== 200) {
          const { body } = getMessage(res.data.status.code);
          showGlobalAlert({ type: 'error', message: body });
        } else if (res.data.status.code === 200) {
          updateUserData({
            ...userData,
            otpDestinations: res.data.result[0].nextStep.params.devices,
            userid: formData[userData.troubleSigninStep],
            otpDeviceList: formatDevices(
              res.data.result[0].nextStep.params.devices
            ),
          });
          changeStep('fistroubleotpchoice');
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userid: formData[userData.troubleSigninStep],
          password: formData.userpassword,
          userpassword: formData.userpassword,
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  // migration check code. can be done pre trouble sign in flow.
  // can be uncommented and used when required :  
  const checkMigStatus = async () => {
    setLoading(true);
    const info = formData[userData.troubleSigninStep];
    const validMail = isValidEmail(info);
    try {
      const {
        data,
      } = await axios.post(
        `${process.env.REACT_APP_IDM_API}authentication/migration-status-check`,
        {
          [validMail ? 'email' : 'userId']: info,
        },
        {
          headers: {
            appid: 'idmauto',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      );
      return data;
    } catch (error) {
      setLoading(false);
      console.log(error);
      showGlobalAlert({
        type: 'error',
        message: getMessage('defaultCode').body,
      });
    }
  };
  const setErrors = () => {
    const tstep = userData.troubleSigninStep;
    const data = formData[userData.troubleSigninStep];
    switch (tstep) {
      case 'troublesigninforgotuser':
        if (!isValidEmail(data) || data === '') {
          setFormErrors({
            ...formErrors,
            [tstep]: 'Please enter valid Email Address',
          });
        }
        break;
      case 'troublesigninforgotpass':
      case 'troublesigninaccountlocked':
      default:
        if (data === '') {
          setFormErrors({
            ...formErrors,
            [tstep]: 'User ID / Email is required'
          });
        }
        break;
    }
  };

  const performBatchAuth = ({ userId, email }) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/batch/batchAuth`,
        {
          userId,
        },
        {
          withCredentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 111) {
          showGlobalAlert({
            type: 'error',
            message: getMessage(111).body,
          });
        } else if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: 'error',
            message: getMessage('defaultCode').body,
          });
        } else {
          showGlobalAlert({
            type: 'success',
            message: t(
              'A link to reset your password has been sent to the registered email address %emailId%.',
              { emailId: emailMask(email) }
            ),
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
        console.error(err);
        updateUserData({
          ...userData,
          password: '',
          userpassword: '',
        });
      });
  };
  // testcode delete please const alertcheck = () => {
  //   const email = 'jognjoe@thuoru.io';
  //   showGlobalAlert({
  //     type: 'error',
  //     message: t(
  //       'A link to reset your password has been sent to the registered email address %emailId%.',
  //       { emailId: emailMask(email) }
  //     ),
  //   });
  // }
  const submitUsername = async () => {
    if (
      !formData[userData.troubleSigninStep] ||
      formErrors[userData.troubleSigninStep] ||
      !rememberOptions.some((opt) => opt.isChecked)
    ) {
      setErrors();
    } else {
      usernameFullFlow();
    }
  };

  const onFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'troublesigninforgotuser':
        if (!isValidEmail(e.target.value) || e.target.value === '') {
          setFormErrors({
            ...formErrors,
            [e.target.name]: 'Please enter valid Email Address',
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: '',
          });
        }
        break;
      case 'troublesigninforgotpass':
      case 'troublesigninaccountlocked':
      default:
        if (e.target.value === '') {
          setFormErrors({
            ...formErrors,
            [e.target.name]: 'User ID / Email is required'
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: ''
          });
        }
        break;
    }
  };

  const onCheckBoxChange = (event) => {
    showGlobalAlert({ type: '', message: '' });
    let tempOptions = [...rememberOptions];
    tempOptions.forEach((options) => {
      options.isChecked = options.name === event.target.name;
    });
    setRememberOptions(tempOptions);
    updateUserData({ ...userData, troubleSigninStep: event.target.name });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: '', message: '' });
    updateUserData({ ...userData, troubleSigninStep: 'troublesigninforgotuser' });
    changeStep('fislogin');
  }

  return (
    <FISWrapper>
      <Box>
        <div style={{ width: '90%' }}>
          <div className={styles.backArrow}>
            <div data-testid="backButtonTSI">
              <ArrowBackIcon data-testid="backBtnTSI" onClick={navigateBack} />
            </div>
            <h4 className={styles.middle_heading}>
              {t('Login troubleshooter')}
            </h4>
          </div>
          {globalAlert.message && (
            <FisAlert
              message={globalAlert.message}
              type={globalAlert.type}
            />
          )}
          <div style={{ fontSize: '14px', fontWeight: '700', marginTop: '20px' }}>
            Select your issue:</div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span
              style={{
                font: 'normal normal normal 14px/16px Arial',
                letterSpacing: '0.1px',
                color: '#DF0000',
                opacity: 1,
              }}
              role="alert"
            >
              {formErrors.userid}
            </span>
            <RadioGroup>
              {rememberOptions.map((item) => (
                <FormControlLabel
                  value={`${item.id}_${item.name}`}
                  name={item.name}
                  key={item.id}
                  data-testid={item.id}
                  control={
                    <Radio
                      size="small"
                      style={{ color: '#D0D0D0' }}
                      classes={{ checked: styles.radioColor }}
                      checked={item.isChecked}
                    />
                  }
                  label={t(item.value)}
                  onChange={onCheckBoxChange}
                  classes={{
                    label: styles.radioLabel,
                  }}
                />
              ))}
            </RadioGroup>
            <Box
              style={{
                marginTop: '1rem',
                maxWidth: '394px',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {userData.troubleSigninStep &&
                userData.troubleSigninStep === 'troublesigninforgotuser'
                ? t('Email ID')
                : t(
                  'User ID / Email ID'
                )}
            </Box>
            <Box
              style={
                {
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#909090',
                }
              }
            >
              {userData.troubleSigninStep &&
                userData.troubleSigninStep === 'troublesigninforgotuser'
                ? t('Please provide your Email ID to proceed')
                : t(
                  'Please provide your User ID/ Email ID to proceed'
                )}
            </Box>
            <FormControl
              style={{ width: '100%' }}
              error={formErrors[userData.troubleSigninStep]}
              variant="outlined"
            >
              <OutlinedInput
                id="userid"
                name={userData.troubleSigninStep}
                onChange={onFormData}
                onBlur={onFormData}
                placeholder={t(
                  userData.troubleSigninStep &&
                    userData.troubleSigninStep === 'troublesigninforgotuser'
                    ? 'Email'
                    : 'Enter User ID / Email Address'
                )}
                fullWidth
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                value={formData[userData.troubleSigninStep]}
                className={styles.passwordInput}
              />
            </FormControl>
            {formErrors[userData.troubleSigninStep] && (
              <Box className={styles.inlineAlert}>
                <InfoOutlinedIcon fontSize="small" />
                {t(formErrors[userData.troubleSigninStep])}
              </Box>
            )}
            <Box className={styles.btn_wrapper}>
              <Button
                type="submit"
                variant="text"
                // color="secondary"
                onClick={submitUsername}
                style={{ width: '386px' }}
                className={styles.login_button}
                data-testid='continue'
              >
                {t('Continue')}
              </Button>
            </Box>
          </form>
          <Box className={styles.link_wrapper} id="linkBoxWrapper">
            <Link
              component="button"
              variant="body2"
              id="loginLink"
              color="primary"
              onClick={() => {
                changeStep('fislogin');
              }}
              style={{ textDecoration: 'underline' }}
            >
              {t('Log in with a different User ID')}
            </Link>
          </Box>
        </div>
      </Box>
      <LoaderFis open={loading} />

    </FISWrapper >
  );
};

export default FISTroubleSIgnIn;
