import React from 'react';
import { Grid, Box } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import MaskGroup from '../images/auto-img/MaskGroup.svg';
import { useGlobalUserData } from '../lib/globalUserData';

export const FISWrapper = ({ children }) => {
  const { step } = useGlobalUserData();
  return (
    <Box container className={styles.wrapper}>
      <img src={MaskGroup} />
      <Box style={{ width: '416px', padding: '10px' }}>
        <Box className={styles.welcomeHeadingBox}>
        {step === 'fislogin' && (
            <>
              <Box className={styles.welcomeHeading}>
                <Box>Welcome to&nbsp;</Box>
                <Box style={{ color: '#E37617' }}>Dealer Portal!</Box>
              </Box>
              <Box className={styles.welcomeHeadingSmall}>We’ve been expecting you</Box>
            </>
          )
          }
          {step === 'fistroublesignin' && (
            <>
              <Box className={styles.welcomeHeading}>
                <Box>Let us help you</Box>
              </Box>
              <Box className={styles.welcomeHeadingSmall}>Thats okay, it happens!</Box>
            </>
          )
          }
        </Box>
        <Box className={styles.container}>{children}</Box>
      </Box>
    </Box>
  );
};
