import { OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './CustomOtpInput.module.css';

const CustomOtpInput = ({
  handleChange,
  id,
  handleKeyPress,
  value,
  onFocusChange,
  handleKeyDown,
}) => {
  const [, setVal] = useState('');
  return (
    <OutlinedInput
      name={id}
      id={id}      
      data-testid={id}
      value={value}
      classes={{
        root: styles.backgroundcolor,
        input: styles.inputField,
        notchedOutline: styles.bordercolor,
      }}
      inputProps={{
        maxLength: 1,
        inputMode: 'numeric',
      }}
      onChange={(e) => {
        handleChange(e);
        setVal(e.target.value);
      }}
      onKeyUp={handleKeyPress}
      onFocus={onFocusChange}
      onKeyDown={handleKeyDown}
      // autoFocus={id === 'field1' ? true : false}
    />
  );
};

export default CustomOtpInput;
