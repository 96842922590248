import { makeStyles } from '@material-ui/core';
export const topHeaderStyles = () => {
  return makeStyles((theme) => ({
    top_header: {
      display: 'flex',
      background: '#FFF 0% 0% no-repeat padding-box',
      // opacity: 0.85,
      // justifyContent: "flex-end",
      height: '100px',
      color: '#000000',
      alignItems: 'center',
      // [theme.breakpoints.down('650')]: {
      justifyContent: 'space-between',
      flexWrap: 'inherit',
      padding: '0 6px',

      // }
    },
    username_block: {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.11px',
      opacity: 1,
      marginRight: '30px',
      textTransform: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('650')]: {
        marginRight: '0',
      },
      [theme.breakpoints.down('420')]: {
        font: 'normal normal normal 13px/16px Arial',
        textAlign: 'center',
      },
    },

    oriental: {
      // display: 'none',
      // [theme.breakpoints.down('650')]: {
      display: 'block',
      width: '146px',
      height: '38px',
      // },
      [theme.breakpoints.down('350')]: {
        width: '99px',
      },
    },

    profile_icon: {
      width: '32px',
      height: '26px',
      opacity: 1,
      paddingLeft: '8px',
      [theme.breakpoints.down('370')]: {
        width: '25px',
        height: '25px',
      },
    },

    right_mobile_header: {
      display: 'flex',
      gap: '5px',
      [theme.breakpoints.down('650')]: {
        display: 'flex',
      },
    },

    lang_change_button: {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.11px',
      opacity: 1,
      color: '#000000',
      // marginRight: "40px",
      '&.Mui-disabled span': {
        opacity: 1,
      },
      // [theme.breakpoints.down('650')]: {
      //     marginRight: "0px",
      //     paddingY: "0",
      // },
      [theme.breakpoints.down('420')]: {
        font: 'normal normal normal 13px/16px Arial',
      },
      [theme.breakpoints.down('370')]: {
        marginRight: '0px',
        font: 'normal normal normal 12px/16px Arial',
      },
    },

    language_icon: {
      width: '33px',
      height: '28px',
      opacity: 1,
      paddingRight: '6px',
      [theme.breakpoints.down('370')]: {
        width: '25px',
        height: '25px',
      },
    },

    heading: {
      fontFamily: 'TTNormsRegular',
      fontSize: '34px',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '0.20000000298023224px',
      textAlign: 'left',
    },

    translationToggle: {
      border: '1px solid #909090',
      borderRadius: '5px',
    }
  }));
};
