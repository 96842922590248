/* eslint-disable no-unused-vars */
import React, { useState, useContext, createContext } from 'react';
import { mask } from '../utils/mask';

const userContext = createContext();

const INITIAL_STEP = 'fislogin';
const issueTypes = {
  troublesigninforgotpass: 'PASSWORD',
  troublesigninforgotuser: 'USERNAME',
  troublesigninaccountlocked: 'UNLOCK_ACCOUNT',
};
const INITIAL_STATE = {
  issueTypes,
  troubleSigninStep: 'troublesigninforgotuser',
  otpDeviceList: [],
  policy: [],
  globalShowPasswordField: false,
  rememberMe: localStorage.getItem('rememberedUserId') ? true : false,
  userid: localStorage.getItem('rememberedUserId') || '',
  useridMaskedValue: mask(localStorage.getItem('rememberedUserId') || ''),
  userpassword: '',
  flow: 'AUTH',
};

export function UserDataProvider({ children }) {
  const user = useProvideData();
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
}

export const useGlobalUserData = () => {
  return useContext(userContext);
};

function useProvideData() {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem('success')) || INITIAL_STATE
  );
  const [step, setStep] = useState(INITIAL_STEP);
  const [globalToken, setGlobalToken] = useState({});
  const [globalAlert, setGlobalAlert] = useState({ type: '', message: '' });

  const updateUserData = (data) => {
    setUserData(data);
  };

  const changeStep = (newStep) => {
    setStep(newStep);
  };

  const updateGlobalToken = (data) => {
    setGlobalToken(data);
  };
  const showGlobalAlert = (data) => {
    setGlobalAlert(data);
  };
  return {
    userData,
    updateUserData,
    step,
    changeStep,
    globalToken,
    updateGlobalToken,
    globalAlert,
    showGlobalAlert,
  };
}
