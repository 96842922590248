// An MD5 or SHA hashed value will conform to the above format requirements
export async function sha256(message) {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');        // convert bytes to hex string
}

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
// tmx documentation https://portal.threatmetrix.com/kb/implementation/implementation_overview/sessionid.htm

// # Session ID
// The Session ID is a temporary identifier that is unique to the visitor's session. It is the primary element used to identify and query end-user sessions on customer websites. Both ThreatMetrix Cloud and ThreatMetrix Mobile require a Session ID to be present during a session as detailed below:

// The Session ID must be a unique ThreatMetrix Session ID to be included within the Profiling Tags in order for our service to uniquely identify the end user's session. The Session ID must be generated by the customer's web application.

// ## Generating a Session ID
// Some recommended methods of generating a Session ID are:

//  - Referencing an existing session identifier from a cookie and appending the date and time in milliseconds to the end of the identifier and then applying a hexadecimal hash to the concatenated value to produce a completely unique Session ID.

//  - Referencing an existing session identifier from the web application and applying a hexadecimal hash to the value to obfuscate the identifier.

//  - Generate a completely arbitrary, random number.

//  ## Inserting the Session ID into the Profiling Tags
// Once the Session ID has been generated it needs to be inserted into the profiling tags in the `session_id=` field as in the following example:
 
// ```
// session_id=16a9508e007c82f33572ac0907f1ec1b
// ```
// ## ThreatMetrix SDK
// Please refer to the Using ThreatMetrix Mobile article for detailed information.

// ## Session ID Format
//  - The Session Id may be up to 128 bytes long and must consist of the following characters only:

//  - Upper and lowercase English letters (a-z, A-Z)

//  - Digits (0-9)

//  - Underscore (_)

//  - Hyphen (-)

//  - Session ID Length
//  - session_ids will need to be at least 10 valid characters long, or an empty JS response, `204 (OK)` will be returned. Existing organizations may now request to mandate a minimum length of 10 for a `session_id`.

//  - Minimum length is after sanitization (removal of non-conformant characters).

//  - If the session_id exceeds 128 characters, it will be truncated to 128 characters in the API response.

// ## Session ID Validation
// The validation of the session_id is dependent on whether the Check Session ID flag is set to true or not for your organization. This flag is set to true by default for all new customer organizations. When this flag is set to true, the session_ids will need to be at least 10 valid characters long and no invalid characters must be present. If the session_id does not comply, the profiling call  will fail with a 204 (OK) response and the API response will return the request_result as "`fail_invalid_parameter`" and the error_detail as "`session_id`".

// If this flag is not set, then the minimum length will not matter and any non-conformant characters will be sanitized and the call allowed to proceed.
// ## Recommended:  An MD5 or SHA hashed value will conform to the above format requirements.

// ## Session ID Notes and Limitations 
// Ideally, each profiling session should use a unique Session ID. 

// Multiple API calls may be performed with the same Session ID as long as no new profiling was done, as the calls will access the same session data.

// `handle_id` is a deprecated attribute that has been superseded by session_id. 

