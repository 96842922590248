import React from 'react';
import { Box } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from '../contexts/Localization';
import ErrorIcon from '../images/auto-img/ErrorIcon.png';

const FisAlert = ({ type, message='Please provide a valid One time passcode' }) => {
  const { t } = useTranslation();
  return (
    <Box id='alertMessage' className={type === 'success' ? styles.successAlert : styles.invalidOtp}>
      {type === 'success' ? (
        <InfoOutlinedIcon fontSize="small" style={{ color: '#1976D2', marginRight: '10px' }} />
      ) : (
        <img src={ErrorIcon} style={{ height: '24px', marginRight: '10px' }} />
      )} 
      {t(message)}
    </Box>
  );
};

export default FisAlert;
