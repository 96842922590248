import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CustomOtpInput from './CustomOtpInput';
import styles from './CustomOtpInput.module.css';
import { useTranslation } from '../contexts/Localization';

const CustomOTP = ({
  otpValues,
  handleChange,
  handleResend,
  handleKeyDown,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box style={{ display: 'flex', marginTop: '30px', marginBottom: '15px' }}>
        {Array.from({ length: 6 }, (_, i) => i + 1).map((f) => (
          <CustomOtpInput
            value={otpValues[`field${f}`]}
            id={`field${f}`}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
          />
        ))}
      </Box>
      <Box className={styles.belowDiv}>
        <Box>
          <Button
            className={styles.resend}
            data-testid="resendOtp"
            onClick={handleResend}
          >
            {t('Resend security code')}
          </Button>
          <Box>
            <Typography className={styles.forgot}>
              {t('If you did not receive the code use the resend code link')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomOTP;
